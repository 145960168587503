<app-loading [overlay]="true"></app-loading>
<div *ngIf="isCheckSignIn">
  <div class="wrapper fix-color-button">
    <div class="d-flex min-height-screen">
      <div
        class="sidebar"
        [ngClass]="{
          'inactive-menu': !sidebarService.isSidebarVisible,
          'active-menu': sidebarService.isSidebarVisible
        }"
      >
        <app-sidebar></app-sidebar>
      </div>
      <div class="main-wrapper" [ngClass]="{ 'sidebar-active': sidebarService.isSidebarVisible }">
        <app-header></app-header>
        <div class="main">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="wrapper fix-color-button">
    <app-header></app-header>
    <div class="d-flex main-wrapper">
      <app-sidebar></app-sidebar>
      <div class="main">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div> -->
  <app-footer></app-footer>
</div>

<div *ngIf="!isCheckSignIn">
  <router-outlet></router-outlet>
</div>