<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="cursor-scroll" mat-dialog-title>
  <mat-dialog-content>
    <div cdkDragHandle class="header-dialog topic">
      <label for=""> ยืนยันการลบข้อมูล </label>
      <button (click)="onNoClick()" class="close" type="button">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="content-box">
      <div class="content-detail mb-4">
        <div class="content-border card">
          ชื่อผู้ขาย: {{ name }} <br>
          รหัสประจำตัวผู้เสียภาษี: {{ taxId }}
        </div>
      </div>
      <div class="row mx-auto">
        <div class="w-100 mx-auto text-center mt-4">
          <button (click)="onConfirm()" class="btn-primary" type="button">ยืนยัน</button>
          <button (click)="onNoClick()" class="btn-danger" type="button">ยกเลิก</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>