import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class Utils {
  constructor(private _snackBar: MatSnackBar) {}

  public mappingThaiLanguage(value: any) {
    const compareThaiLanguage = new Map();
    compareThaiLanguage.set('tDate', 'วันที่สั่งโอนเงิน');
    compareThaiLanguage.set('dataDate', 'วันที่ของข้อมูล');
    compareThaiLanguage.set('totalRecord', 'จำนวนข้อมูล');
    compareThaiLanguage.set('vendorId', 'รหัสผู้ขาย');
    compareThaiLanguage.set('vendorName', 'ชื่อผู้ขาย');
    compareThaiLanguage.set('name', 'ชื่อ');
    compareThaiLanguage.set('taxID', 'รหัสประจำตัวผู้เสียภาษี');
    compareThaiLanguage.set('username', 'รหัสประจำตัวผู้เสียภาษี');
    compareThaiLanguage.set('accountNo', 'เลขที่บัญชี');
    // compareThaiLanguage.set('invoiceNo', 'เลขที่บัญชี');
    compareThaiLanguage.set('email', 'E-mail');
    compareThaiLanguage.set('date', 'วันที่');
    compareThaiLanguage.set('registerDate', 'วันที่');
    compareThaiLanguage.set('deleteOption', '');
    compareThaiLanguage.set('actionDate', 'วันที่ Upload');
    compareThaiLanguage.set('modeType', 'ประเภท Upload');
    compareThaiLanguage.set('payTo', 'ส่วนราชการผู้จ่ายชำระเงิน');
    compareThaiLanguage.set('payToUnit', 'หน่วยเบิกจ่าย');
    compareThaiLanguage.set('payToProvince', 'จังหวัด');
    compareThaiLanguage.set('invoiceNo', 'เลขที่ใบแจ้งหนี้');
    // compareThaiLanguage.set('dataDate', 'วันที่สั่งโอนเงิน');
    compareThaiLanguage.set('bankName', 'ธนาคาร/สาขา');
    compareThaiLanguage.set('accountName', 'ชื่อบัญชี');
    compareThaiLanguage.set('amount', 'จำนวนเงินรวม (บาท)');
    compareThaiLanguage.set('amountRate', 'ภาษีหัก ณ ที่จ่าย (บาท)');
    compareThaiLanguage.set('fine', 'ค่าปรับ (บาท)');
    compareThaiLanguage.set('amountTotal', 'จำนวนเงินสุทธิ (บาท)');
    compareThaiLanguage.set('note', 'โอนสิทธิ์/คำอธิบาย');
    compareThaiLanguage.set('resetOption', 'รีเซ็ทรหัสผ่าน');
    compareThaiLanguage.set('deleteOption', 'ลบข้อมูลผู้ขาย');
    return compareThaiLanguage.get(value);
  }

  public checkPath(value: any) {
    const comparePath = new Map();
    comparePath.set('menu', true);
    comparePath.set('register', false);
    comparePath.set('main', true);
    comparePath.set('profile', true);
    comparePath.set('reportLog', true);
    comparePath.set('reportVendor', true);
    comparePath.set('reportTransaction', true);
    comparePath.set('reportUpload', true);
    comparePath.set('question', true);
    comparePath.set('mainManage', true);
    comparePath.set('questionManage', true);
    comparePath.set('logOut', true);
    comparePath.set('reportTransactionUser', true);
    return comparePath.get(value);
  }

  public parseDate(day: string, month: string, year: string) {
    day = +day < 10 ? '0' + day : day;
    month = +month < 10 ? '0' + month : month;
    return year + '-' + month + '-' + day;
  }

  public onCheckValidation(_listCheckValidate: string[], _form: any) {
    if (_listCheckValidate.find((item) => item === 'dateFrom')) {
      if (_form.dateFrom && _form.dateTo) {
        const dateFrom = _form.dateFrom;
        const dateTo = _form.dateTo;
        const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
        const timeDiffInMs = dateTo.getTime() - dateFrom.getTime();
        if (timeDiffInMs >= thirtyDaysInMs) {
          this._snackBar.open('กรุณาระบุวันที่ให้อยู่ภายใน 30 วัน', '', {
            panelClass: '_warning',
          });
          return false;
        }
      }
    }

    if (_listCheckValidate.find((item) => item === 'vendorId')) {
      if (!_form.vendorId) {
        this._snackBar.open('กรุณาระบุรหัสผู้ขาย', '', {
          panelClass: '_warning',
        });
        return false;
      }
    }

    if (_listCheckValidate.find((item) => item === 'oldPassword')) {
      if (!_form.oldPassword) {
        this._snackBar.open('กรุณาระบุรหัสผ่านเก่า', '', {
          panelClass: '_warning',
        });
        return false;
      }
    }

    if (_listCheckValidate.find((item) => item === 'password')) {
      if (!_form.password) {
        this._snackBar.open('กรุณาระบุรหัสผ่านใหม่', '', {
          panelClass: '_warning',
        });
        return false;
      }
    }

    if (_listCheckValidate.find((item) => item === 'verifyPassword')) {
      if (!_form.verifyPassword) {
        this._snackBar.open('กรุณาระบุยืนยันรหัสผ่านใหม่', '', {
          panelClass: '_warning',
        });
        return false;
      }
    }

    if (_listCheckValidate.find((item) => item === 'email')) {
      if (!_form.email) {
        this._snackBar.open('กรุณาระบุอีเมล', '', {
          panelClass: '_warning',
        });
        return false;
      }
    }

    if (_listCheckValidate.find((item) => item === 'username')) {
      if (!_form.username) {
        this._snackBar.open('กรุณาระบุรหัสประจำตัวผู้เสียภาษี', '', {
          panelClass: '_warning',
        });
        return false;
      }
    }

    if (_listCheckValidate.find((item) => item === 'accountNo')) {
      if (!_form.accountNo) {
        this._snackBar.open('กรุณาระบุหมายเลขบัญชี', '', {
          panelClass: '_warning',
        });
        return false;
      }
    }

    return true;
  }
}
