<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="cursor-scroll" mat-dialog-title>
  <mat-dialog-content>
    <!-- <div> -->
      <div cdkDragHandle class="header-dialog topic">
        <label for=""> รหัสผ่านใหม่ </label>
        <button (click)="onNoClick()" class="close" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- <div class="content-dialog overflow-y max-h-400"> -->
        <div class="content-box">
          <div class="content-detail mb-4">
            <div class="content-border card">
              {{data}}
            </div>
          </div>
        </div>
      <!-- </div> -->
    <!-- </div> -->
  </mat-dialog-content>
</div>
