import { Pipe, PipeTransform } from '@angular/core';
import { Decimal } from 'decimal.js';

@Pipe({
  name: 'bigdecimal',
})
export class CustomDecimalPipe implements PipeTransform {
  transform(n: string): string {
    if (n !== null && n !== undefined && n !== '') {
      return new Decimal(n).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return '';
    }
  }
}
