import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/core/services/user/user.service';
import { interval } from 'rxjs';
import {DialogShowPasswordComponent} from "../dialog-show-password/dialog-show-password.component";
export interface DialogData {
  rowData: any;
}

@Component({
  selector: 'app-dialog-confirm-remove',
  templateUrl: './dialog-reset-password.component.html',
  styleUrls: ['./dialog-reset-password.component.scss'],
})
export class DialogResetPasswordComponent implements OnInit {
  listResult = [];
  listResultError = [];
  tableSucces = true;

  isDisableRefresh = true;

  allPage: any;

  name: string = '';
  taxId: string = '';
  id: any;

  constructor(
    public dialogRef: MatDialogRef<DialogResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _snackBar: MatSnackBar,
    private userService: UserService,
    private dialog: MatDialog,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.name = this.data.rowData.name || '';
    this.taxId = this.data.rowData.username || '';
    this.id = this.data.rowData.id || null;
  }

  onNoClick(): void {
    this.dialogRef.close({ event: true, close: true });
  }

  onConfirm(){
    if(this.id){
      this.userService.resetPassword(this.id).then((response) => {
        console.log('response',response)
        if (!response.error) {
          this.dialogRef.close({ event: true, close: true });
          this.dialog.open(DialogShowPasswordComponent, {
            width: '30vw',
            data: response,
          });
          // interval(2000).subscribe(() => {
          //   window.location.replace('/reportVendor');
          // });
        }
      });
    }
  }
}
