import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { TransactionElement } from 'src/app/core/models/transaction-element-response';
import { TransactionUserElement } from 'src/app/core/models/transaction-user-element';
import { VendorElement } from 'src/app/core/models/vendor-element';
import { TransactionService } from 'src/app/core/services/transaction/transaction.service';
import { Constant } from '../../constants';
import { Utils } from '../../utils/utils';
import { DialogNoteViewComponent } from '../dialog-note-view/dialog-note-view.component';
import { saveAs } from 'file-saver';
import { UserService } from 'src/app/core/services/user/user.service';
import { DialogConfirmRemoveComponent } from '../dialog-confirm-remove/dialog-confirm-remove.component';
import { LogElement } from 'src/app/core/models/log-element-response';
import { LogService } from 'src/app/core/services/log/log.service';
import { UploadLogElement } from 'src/app/core/models/upload-log-element-response';
import {DialogResetPasswordComponent} from "../dialog-reset-password/dialog-reset-password.component";
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnChanges {
  @Input() criteria: any;
  @Input() page: any;
  @Output() payback: EventEmitter<any> = new EventEmitter<any>();

  displayedColumns: any[] = [];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  isCheckData = false;
  listData: any[] = [];
  totalElements: number = 0;
  pages = {
    number: 0,
    limit: 100,
  };

  constructor(
    public utils: Utils,
    public constant: Constant,
    private dialog: MatDialog,
    private transactionService: TransactionService,
    private logService: LogService,
    private userService: UserService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnChanges(): void {
    this.listData = [];
    this.totalElements = 0;
    this.pages = {
      number: 0,
      limit: 100,
    };
    this.dataSource = new MatTableDataSource<any>();
    this.onSearch();
    this.dataSource.paginator = this.paginator;
  }

  onSearch() {
    if (this.page === 'TransactionElement') {
      this.displayedColumns = this.constant.TransactionDisplayedColumns;
      const payload = {
        pages: this.pages,
        query: this.criteria,
      };
      this.transactionService.search(payload).then((response) => {
        if (response.items) {
          console.log(response.items)
          if (response.items.content.length > 0) {
            this.totalElements = response.items.totalElements;
            this.isCheckData = true;
            const ELEMENT_DATA: TransactionElement[] = response.items.content;
            this.dataSource = new MatTableDataSource<TransactionElement>(
              ELEMENT_DATA
            );
            this.dataSource.data.forEach((item) => {
              this.listData.push([
                item.id,
                item.filename,
                item.module,
                item.status,
              ]);
            });
          } else {
            this.isCheckData = false;
            this.onWarningBar();
          }
        }
      });
    }
    if (this.page === 'VendorElement') {
      this.displayedColumns = this.constant.VendorDisplayedColumns;
      const payload = {
        pages: this.pages,
        query: this.criteria,
      };
      this.userService.search(payload).then((response) => {
        if (response.items) {
          if (response.items.content.length > 0) {
            this.totalElements = response.items.totalElements;
            this.isCheckData = true;
            const ELEMENT_DATA: VendorElement[] = response.items.content;
            this.dataSource = new MatTableDataSource<VendorElement>(
              ELEMENT_DATA
            );
            this.dataSource.data.forEach((item) => {
              this.listData.push([
                item.id,
                item.filename,
                item.module,
                item.status,
              ]);
            });
          } else {
            this.isCheckData = false;
            this.onWarningBar();
          }
        }
      });
    }
    if (this.page === 'TransactionUserElement') {
      this.displayedColumns = this.constant.TransactionUserDisplayedColumns;
      const payload = {
        pages: this.pages,
        query: this.criteria,
      };
      this.transactionService.search(payload).then((response) => {
        if (response.items) {
          if (response.items.content.length > 0) {
            this.totalElements = response.items.totalElements;
            this.isCheckData = true;
            const ELEMENT_DATA: TransactionUserElement[] =
              response.items.content;
            this.dataSource = new MatTableDataSource<TransactionUserElement>(
              ELEMENT_DATA
            );
            this.dataSource.data.forEach((item) => {
              this.listData.push([
                item.id,
                item.filename,
                item.module,
                item.status,
              ]);
            });
          } else {
            this.isCheckData = false;
            this.onWarningBar();
          }
        }
      });
    }
    if (this.page === 'LogElement') {
      this.displayedColumns = this.constant.LogDisplayedColumns;
      const payload = {
        pages: this.pages,
        query: this.criteria,
      };
      this.logService.searchLog(payload).then((response) => {
        if (response.items) {
          if (response.items.content.length > 0) {
            this.totalElements = response.items.totalElements;
            this.isCheckData = true;
            const ELEMENT_DATA: LogElement[] = response.items.content;
            this.dataSource = new MatTableDataSource<LogElement>(
              ELEMENT_DATA
            );
            this.dataSource.data.forEach((item) => {
              this.listData.push([
                item.id,
                item.filename,
                item.module,
                item.status,
              ]);
            });
          } else {
            this.isCheckData = false;
            this.onWarningBar();
          }
        }
      });
    }
    if (this.page === 'UploadLogElement') {
      this.displayedColumns = this.constant.UploadLogDisplayedColumns;
      const payload = {
        pages: this.pages,
        query: this.criteria,
      };
      this.logService.search(payload).then((response) => {
        if (response.items) {
          if (response.items.content.length > 0) {
            this.totalElements = response.items.totalElements;
            this.isCheckData = true;
            const ELEMENT_DATA: UploadLogElement[] = response.items.content;
            this.dataSource = new MatTableDataSource<UploadLogElement>(
              ELEMENT_DATA
            );
            this.dataSource.data.forEach((item) => {
              this.listData.push([
                item.id,
                item.filename,
                item.module,
                item.status,
              ]);
            });
          } else {
            this.isCheckData = false;
            this.onWarningBar();
          }
        }
      });
    }
  }

  handlePage(event: any) {
    this.pages.number = event.pageIndex;
    this.pages.limit = event.pageSize;
    this.isCheckData = false;
    this.onSearch();
  }

  isCheckColumnType(column: any) {
    if (this.page === 'VendorElement') {
      return this.constant.LIST_COLUMN_VENDOR.find(
        (item) => item.key === column
      )?.type;
    }
    if (this.page === 'TransactionElement') {
      return this.constant.LIST_COLUMN_TRANSACTION.find(
        (item) => item.key === column
      )?.type;
    }
    if (this.page === 'TransactionUserElement') {
      return this.constant.LIST_COLUMN_TRANSACTION_USER.find(
        (item) => item.key === column
      )?.type;
    }
    if (this.page === 'LogElement') {
      return this.constant.LIST_COLUMN_LOG.find(
        (item) => item.key === column
      )?.type;
    }
    if (this.page === 'UploadLogElement') {
      return this.constant.LIST_COLUMN_UPLOAD_LOG.find(
        (item) => item.key === column
      )?.type;
    }
    return 'text';
  }

  onWarningBar() {
    this._snackBar.open('ไม่พบข้อมูล', '', {
      panelClass: '_warning',
    });
  }

  openDialogNote(note: any) {
    this.dialog.open(DialogNoteViewComponent, {
      width: '30vw',
      data: { note },
    });
  }

  openDialogRemove(rowData: any) {
    this.dialog.open(DialogConfirmRemoveComponent, {
      width: '30vw',
      data: { rowData },
    });
  }

  openDialogReset(rowData: any) {
    this.dialog.open(DialogResetPasswordComponent, {
      width: '30vw',
      data: { rowData },
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onCheckNote(value: any) {
    if (value !== null) {
      return true;
    } else {
      return false;
    }
  }

  onExport() {
    if (this.page === 'TransactionElement' || this.page === 'TransactionUserElement') {
      const payload = this.criteria;
      this.transactionService.exportExcel(payload).then((response) => {
        if (!!response) {
          const blob = new Blob([response], {
            type: 'application/octet-stream',
          });
          saveAs(blob, new Date().getTime() + '.xlsx');
        }
      });
    }
    if (this.page === 'VendorElement') {
      const payload = this.criteria;
      this.userService.exportExcel(payload).then((response) => {
        if (!!response) {
          const blob = new Blob([response], {
            type: 'application/octet-stream',
          });
          saveAs(blob, new Date().getTime() + '.xlsx');
        }
      });
    }
    if (this.page === 'UploadLogElement') {
      const payload = this.criteria;
      this.logService.exportExcelUploadLog(payload).then((response) => {
        if (!!response) {
          const blob = new Blob([response], {
            type: 'application/octet-stream',
          });
          saveAs(blob, new Date().getTime() + '.xlsx');
        }
      });
    }
    if (this.page === 'LogElement') {
      const payload = this.criteria;
      this.logService.exportExcelLog(payload).then((response) => {
        if (!!response) {
          const blob = new Blob([response], {
            type: 'application/octet-stream',
          });
          saveAs(blob, new Date().getTime() + '.xlsx');
        }
      });
    }
  }

  onExportPDF() {

  }
}
