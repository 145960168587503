<ng-container>
  <div [hidden]="!loading" class="overlay">
    <div class="center">
      <ng-template [ngTemplateOutlet]="progressSpinner"></ng-template>
    </div>
  </div>
</ng-container>
<ng-template #progressSpinner>
  <div class="uploader-status text-center">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <!--    <mat-progress-spinner-->
    <!--      [diameter]="diameter"-->
    <!--      [mode]="mode"-->
    <!--      [strokeWidth]="strokeWidth"-->
    <!--      [value]="value"-->
    <!--    >-->
    <!--    </mat-progress-spinner>-->
    <p class="loading-text">กำลังโหลด ...</p>
  </div>
</ng-template>
