import { Component, Input, OnInit } from '@angular/core';
import { LoadingScreenService } from 'src/app/core/services/loading-screen/loading-screen.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  @Input() value = 100;
  @Input() diameter = 100;
  @Input() mode = 'indeterminate';
  @Input() strokeWidth = 10;
  @Input() overlay = false;
  @Input() color = 'primary';
  loading: boolean | undefined;

  constructor(private loadingScreenService: LoadingScreenService) {
    this.loadingScreenService.loadingVisableChange.subscribe((value: boolean | undefined) => {
      setTimeout(() => (this.loading = value), 0);
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
}
