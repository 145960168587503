<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="cursor-scroll" mat-dialog-title>
  <mat-dialog-content>
    <div cdkDragHandle class="header-dialog topic">
      <label for=""> เพิ่มคำถาม - คำตอบ </label>
      <button (click)="onNoClick()" class="close" type="button">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="content-box">
      <div class="content-detail mb-4">
        <div class="content-border card">
          <form [formGroup]="faqFormGroup">
            <div class="row">
              <div class="col-12 col-md-1"></div>
              <div class="col-12 col-md-2">
                <label class="text-red"> คำถาม </label>
              </div>
              <div class="col-12 col-md-9">
                <textarea class="form-control" autocomplete="off" formControlName="question" name="question"></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-1"></div>
              <div class="col-12 col-md-2">
                <label class="text-red"> คำตอบ </label>
              </div>
              <div class="col-12 col-md-9">
                <textarea class="form-control" autocomplete="off" formControlName="answer" name="answer"></textarea>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row mx-auto">
        <div class="w-100 mx-auto text-center mt-4">
          <button (click)="onConfirm()" class="btn-success" type="button">บันทึก</button>
          <button (click)="onNoClick()" class="btn-danger" type="button">ยกเลิก</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>