<div (click)="mainPage()" class="main-logo link">
  <div class="head-left">
    <div class="head-logo"></div>
  </div>
</div>
<div class="sidebar-btn-close">
  <button class="btn bg-white m-0" (click)="toggleSideBar(!sidebarService.isSidebarVisible)">
    <span class="material-icons" *ngIf="sidebarService.isSidebarVisible">menu_open</span>
    <span class="material-icons" *ngIf="!sidebarService.isSidebarVisible">menu</span>
  </button>
</div>

<mat-sidenav-container class="sidebar">
  <mat-sidenav #sidenav mode="side" [(opened)]="opened" (opened)="events.push('open!')"
    (closed)="events.push('close!')">
    <div class="menu">
      <div class="user-box">
        <div>
          <label> รหัสผู้ใช้ : </label>
          <span *ngIf="userProfile !== null && userProfile !== undefined"> {{ userProfile?.username }} </span>
        </div>
        <div>
          <label> ชื่อผู้ขาย : </label>
          <span *ngIf="userProfile !== null && userProfile !== undefined"> {{ userProfile?.name }} </span>
        </div>
        <div>
          <label> เข้าสู่ระบบเมื่อ : </label>
          <span *ngIf="userProfile !== null && userProfile !== undefined"> {{ userProfile?.loginDate | date: 'HH:mm:ss'
            }}
          </span>
        </div>
        <div class="d-flex mt-2">
          <label><a (click)="logout()" class="pr-2" href="#"> ออกจากระบบ </a></label>
        </div>
      </div>
      <div class="bg-main-blue-gradient">
        <strong> {{ headMenu }} </strong>
      </div>
      <div *ngFor="let item of listMenu">
        <div class="menu-list">
          <ul>
            <li *ngIf="item.role === permission">
              <p [ngClass]="{ active: item.active }" (click)="onActive(item.path)" class="item">
                » {{item.name}}
              </p>
            </li>
            <hr>
          </ul>
        </div>
      </div>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
