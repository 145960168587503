import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SidebarService } from './services/sidebar.service';
import { LoadingScreenService } from './services/loading-screen/loading-screen.service';
import { ApiService } from './services';
import { UserService } from './services/user/user.service';
import { TransactionService } from './services/transaction/transaction.service';
import { LocalStorageService } from './services/local-storage.service';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { AuthService } from './services/auth/auth.service';
import { LogService } from './services/log/log.service';
import { MainPageService } from './services/main/main.service';
import { FAQService } from './services/faq/faq.service';

export const httpInterceptorProviders = [];

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [
    httpInterceptorProviders,
    ApiService,
    SidebarService,
    LoadingScreenService,
    UserService,
    AuthService,
    TransactionService,
    LocalStorageService,
    LogService,
    MainPageService,
    FAQService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
