<div [hidden]="!(dataSource.data.length > 0)" #table>

    <mat-form-field appearance="standard">
        <mat-label>ค้นหา</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
    </mat-form-field>

    <span (click)="onExport()">
        <img src="../../../../assets/images/icon/excel.png" />
    </span>
    <!-- <span (click)="onExportPDF()">
        <img src="../../../../assets/images/icon/pdf.png" />
    </span> -->

    <table mat-table [dataSource]="dataSource" matSort class="table-scroll">
        <div *ngFor="let column of displayedColumns">
            <ng-container [matColumnDef]="column">
                <th mat-header-cell *matHeaderCellDef> {{ column | thaiLang }} </th>
                <td mat-cell *matCellDef="let element">
                    <p class="center" *ngIf="isCheckColumnType(column) === 'code'">{{ element[column] }}</p>
                    <p class="left" *ngIf="isCheckColumnType(column) === 'text'">{{ element[column] }}</p>
                    <p class="center" *ngIf="isCheckColumnType(column) === 'date'">{{ element[column] | thaidate }}</p>
                    <p class="right" *ngIf="isCheckColumnType(column) === 'currency'">{{ element[column] | bigdecimal }}</p>
                    <span
                        class="material-icons icon-refresh"
                        *ngIf="isCheckColumnType(column) === 'icon-reset'"
                        (click)="openDialogReset(element)">
                        refresh
                    </span>
                    <span
                        class="material-icons icon"
                        *ngIf="isCheckColumnType(column) === 'icon-delete'"
                        (click)="openDialogRemove(element)">
                        delete
                    </span>
                    <span
                        class="material-icons icon-list"
                        *ngIf="isCheckColumnType(column) === 'icon-document' && onCheckNote(element[column])"
                        (click)="openDialogNote(element[column])">
                        list_alt
                    </span>
<!--                    <p class="left" *ngIf="isCheckColumnType(column) === 'change-value' &&  element[column] === 1">Vendor</p>-->
                    <p class="left" *ngIf="isCheckColumnType(column) === 'change-value' &&  element[column] === 2">Auto</p>
                </td>
            </ng-container>
        </div>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator (page)="handlePage($event)" [length]="totalElements" [pageSizeOptions]="[50, 100]"
                   aria-label="Select page">
    </mat-paginator>
</div>
