import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LoadingComponent } from './component/loading/loading.component';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { DateInputDirective } from './directives/date-input.directive';
import { TableComponent } from './component/table/table.component';
import { ThaiLangPipe } from './pipe/thai-lang.pipe';
import { DatepickerHeaderComponent } from './component/datepicker-header/datepicker-header.component';
import { ThaidatePipe } from './pipe/thaidate.pipe';
import { CustomDecimalPipe } from './pipe/custom-decimal.pipe';
import { DialogNoteViewComponent } from './component/dialog-note-view/dialog-note-view.component';
import { DialogConfirmRemoveComponent } from './component/dialog-confirm-remove/dialog-confirm-remove.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import {MatExpansionModule} from '@angular/material/expansion';
import { DialogFAQComponent } from './component/dialog-faq/dialog-faq.component';
import { DialogFAQEditComponent } from './component/dialog-faq-edit/dialog-faq-edit.component';
import { DialogFAQDeleteComponent } from './component/dialog-faq-delete/dialog-faq-delete.component';
import {DialogResetPasswordComponent} from "./component/dialog-reset-password/dialog-reset-password.component";
import {DialogShowPasswordComponent} from "./component/dialog-show-password/dialog-show-password.component";


@NgModule({
  declarations: [
    LoadingComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    DateInputDirective,
    TableComponent,
    ThaiLangPipe,
    ThaidatePipe,
    CustomDecimalPipe,
    DatepickerHeaderComponent,
    DialogNoteViewComponent,
    DialogConfirmRemoveComponent,
    DialogFAQComponent,
    DialogFAQEditComponent,
    DialogFAQDeleteComponent,
    DialogResetPasswordComponent,
    DialogShowPasswordComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    DragDropModule,
    ReactiveFormsModule,
    FormsModule,
    MatTabsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatTableModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDialogModule,
    DragDropModule,
    FormsModule,
    ScrollingModule,
    MatRadioModule,
    MatSortModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatListModule,
    MatPaginatorModule,
    RouterModule,
    AngularEditorModule,
    MatExpansionModule,
  ],
  exports: [
    HttpClientModule,
    DatePipe,
    CommonModule,
    HttpClientModule,
    DragDropModule,
    ReactiveFormsModule,
    FormsModule,
    MatTabsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatTableModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDialogModule,
    DragDropModule,
    FormsModule,
    ScrollingModule,
    MatRadioModule,
    MatSortModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatListModule,
    AngularEditorModule,
    MatExpansionModule,

    LoadingComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    DateInputDirective,
    TableComponent,
    ThaiLangPipe,
    ThaidatePipe,
    CustomDecimalPipe,
    DatepickerHeaderComponent,
    DialogNoteViewComponent,
    DialogConfirmRemoveComponent,
    DialogFAQComponent,
    DialogFAQEditComponent,
    DialogFAQDeleteComponent,
    DialogResetPasswordComponent,
    DialogShowPasswordComponent
  ],
  providers: [],
  entryComponents: [],
})
export class SharedModule {}
