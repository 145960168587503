import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { SharedModule } from './shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { CoreModule } from './core/core.module';
import { Constant } from './shared/constants';
import { Utils } from './shared/utils/utils';
import {JwtModule} from "@auth0/angular-jwt";

@NgModule({
  declarations: [AppComponent],
  imports: [PagesModule, CoreModule, SharedModule, BrowserModule, AppRoutingModule, ReactiveFormsModule, BrowserAnimationsModule, JwtModule.forRoot({
    config: {
      tokenGetter: () => {
        return localStorage.getItem('access_token');
      }
    }
  })],
  exports: [],
  providers: [
    Constant,
    FormBuilder,
    Utils,
    { provide: MAT_DATE_LOCALE, useValue: 'th-TH' },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 3000, verticalPosition: 'top' },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
