import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { catchError, map, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor(private httpClient: HttpClient) {}

  search(payload: any): Promise<any> {
    return this.httpClient
      .post(`${environment.apiUrl}` + '/uploadLog/search', payload)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return of(err);
        }),
        take(1),
      )
      .toPromise();
  }

  searchLog(payload: any): Promise<any> {
    return this.httpClient
      .post(`${environment.apiUrl}` + '/log/search', payload)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return of(err);
        }),
        take(1),
      )
      .toPromise();
  }

  exportExcelUploadLog(payload: any): Promise<any> {
    return this.httpClient
      .post(`${environment.apiUrl}` + '/uploadLog/exportExcelUploadLog', payload
        ,{ responseType: 'arraybuffer' })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return of(err);
        }),
        take(1),
      )
      .toPromise();
  }

  exportExcelLog(payload: any): Promise<any> {
    return this.httpClient
      .post(`${environment.apiUrl}` + '/uploadLog/exportExcelLog', payload
        ,{ responseType: 'arraybuffer' })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return of(err);
        }),
        take(1),
      )
      .toPromise();
  }
}
