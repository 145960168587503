import { Injectable } from '@angular/core';

@Injectable()
export class Constant {

  public MONTH_FULL_TH = [
    { month: '01', name: 'มกราคม' },
    { month: '02', name: 'กุมภาพันธ์' },
    { month: '03', name: 'มีนาคม' },
    { month: '04', name: 'เมษายน' },
    { month: '05', name: 'พฤษภาคม' },
    { month: '06', name: 'มิถุนายน' },
    { month: '07', name: 'กรกฎาคม' },
    { month: '08', name: 'สิงหาคม' },
    { month: '09', name: 'กันยายน' },
    { month: '10', name: 'ตุลาคม' },
    { month: '11', name: 'พฤศจิกายน' },
    { month: '12', name: 'ธันวาคม' },
  ];

  public LIST_MENU = [
    // Role Admin
    {
      name: 'หน้าหลัก',
      path: 'main',
      role: 'ADMIN',
      active: true,
    },
    {
      name: 'ข้อมูลส่วนตัว',
      path: 'profile',
      role: 'ADMIN',
      active: false,
    },
    {
      name: 'เปลี่ยนอีเมล',
      path: 'changeEmail',
      role: 'ADMIN',
      active: false,
    },
    {
      name: 'รายงาน Log',
      path: 'reportLog',
      role: 'ADMIN',
      active: false,
    },
    {
      name: 'รายงานข้อมูลผู้ขาย',
      path: 'reportVendor',
      role: 'ADMIN',
      active: false,
    },
    {
      name: 'รายงานการจ่ายชำระเงิน',
      path: 'reportTransaction',
      role: 'ADMIN',
      active: false,
    },
    {
      name: 'รายงานการอัพโหลด',
      path: 'reportUpload',
      role: 'ADMIN',
      active: false,
    },
    {
      name: 'ถาม - ตอบ',
      path: 'question',
      role: 'ADMIN',
      active: false,
    },
    {
      name: 'จัดการหน้าหลัก',
      path: 'mainManage',
      role: 'ADMIN',
      active: false,
    },
    {
      name: 'จัดการถาม - ตอบ',
      path: 'questionManage',
      role: 'ADMIN',
      active: false,
    },
    // Role User
    {
      name: 'หน้าหลัก',
      path: 'main',
      role: 'USER',
      active: true,
    },
    {
      name: 'ข้อมูลส่วนตัว',
      path: 'profile',
      role: 'USER',
      active: false,
    },
    {
      name: 'เปลี่ยนอีเมล',
      path: 'changeEmail',
      role: 'USER',
      active: false,
    },
    {
      name: 'รายงานการจ่ายชำระเงิน',
      path: 'reportTransactionUser',
      role: 'USER',
      active: false,
    },
    {
      name: 'ถาม - ตอบ',
      path: 'question',
      role: 'USER',
      active: false,
    },
    // Oper
    {
      name: 'รายงานข้อมูลผู้ขาย',
      path: 'reportVendor',
      role: 'OPERATOR',
      active: false,
    },
    {
      name: 'รายงานการจ่ายชำระเงิน',
      path: 'reportTransaction',
      role: 'OPERATOR',
      active: false,
    },
  ];

  public LIST_COLUMN_TRANSACTION = [
    { key: 'vendorName', type: 'text', permission: 'ADMIN' },
    { key: 'payTo', type: 'text', permission: 'ADMIN' },
    { key: 'payToUnit', type: 'text', permission: 'ADMIN' },
    { key: 'payToProvince', type: 'text', permission: 'ADMIN' },
    { key: 'invoiceNo', type: 'code', permission: 'ADMIN' },
    { key: 'tDate', type: 'date', permission: 'ADMIN' },
    { key: 'bankName', type: 'text', permission: 'ADMIN' },
    { key: 'accountName', type: 'text', permission: 'ADMIN' },
    { key: 'accountNo', type: 'code', permission: 'ADMIN' },
    { key: 'amount', type: 'currency', permission: 'ADMIN' },
    { key: 'amountRate', type: 'currency', permission: 'ADMIN' },
    { key: 'fine', type: 'currency', permission: 'ADMIN' },
    { key: 'amountTotal', type: 'currency', permission: 'ADMIN' },
    { key: 'note', type: 'icon-document', permission: 'ADMIN' },
  ];

  public LIST_COLUMN_TRANSACTION_USER = [
    { key: 'payTo', type: 'text', permission: 'USER' },
    { key: 'payToUnit', type: 'text', permission: 'USER' },
    { key: 'payToProvince', type: 'text', permission: 'USER' },
    { key: 'invoiceNo', type: 'code', permission: 'USER' },
    { key: 'tDate', type: 'date', permission: 'USER' },
    { key: 'bankName', type: 'text', permission: 'USER' },
    { key: 'accountName', type: 'text', permission: 'USER' },
    { key: 'accountNo', type: 'code', permission: 'USER' },
    { key: 'amount', type: 'currency', permission: 'USER' },
    { key: 'amountRate', type: 'currency', permission: 'USER' },
    { key: 'fine', type: 'currency', permission: 'USER' },
    { key: 'amountTotal', type: 'currency', permission: 'USER' },
    { key: 'note', type: 'icon-document', permission: 'USER' },
  ];

  public LIST_COLUMN_VENDOR = [
    { key: 'vendorId', type: 'code', permission: 'ADMIN' },
    { key: 'name', type: 'text', permission: 'ADMIN' },
    { key: 'username', type: 'code', permission: 'ADMIN' },
    { key: 'accountNo', type: 'code', permission: 'ADMIN' },
    { key: 'email', type: 'text', permission: 'ADMIN' },
    { key: 'registerDate', type: 'date', permission: 'ADMIN' },
    { key: 'resetOption', type: 'icon-reset', permission: 'ADMIN' },
    { key: 'deleteOption', type: 'icon-delete', permission: 'ADMIN' },
  ];

  public LIST_COLUMN_LOG = [
    { key: 'dataDate', type: 'date', permission: 'ADMIN' },
    { key: 'totalRecord', type: 'code', permission: 'ADMIN' },
  ];

  public LIST_COLUMN_UPLOAD_LOG = [
    { key: 'dataDate', type: 'date', permission: 'ADMIN' },
    { key: 'actionDate', type: 'date', permission: 'ADMIN' },
    { key: 'totalRecord', type: 'code', permission: 'ADMIN' },
    { key: 'modeType', type: 'change-value', permission: 'ADMIN' },
  ];

  public TransactionDisplayedColumns = [
    'vendorName',
    'payTo',
    'payToUnit',
    'payToProvince',
    'invoiceNo',
    'tDate',
    'bankName',
    'accountName',
    'accountNo',
    'amount',
    'amountRate',
    'fine',
    'amountTotal',
    'note',
  ];

  public TransactionUserDisplayedColumns = [
    'payTo',
    'payToUnit',
    'payToProvince',
    'invoiceNo',
    'tDate',
    'bankName',
    'accountName',
    'accountNo',
    'amount',
    'amountRate',
    'fine',
    'amountTotal',
    'note',
  ];

  public VendorDisplayedColumns = [
    'vendorId',
    'name',
    'username',
    'accountNo',
    'email',
    'registerDate',
    'resetOption',
    'deleteOption',
  ];

  public LogDisplayedColumns = [
    'dataDate',
    'totalRecord',
  ];

  public UploadLogDisplayedColumns = [
    'dataDate',
    'actionDate',
    'totalRecord',
    'modeType',
  ];

}
