import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard} from "./core/services/guards/auth.guard";

const routes: Routes = [
  {
    path: 'menu',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/menu/menu.module').then((m) => m.MenuModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/sign-in/sign-in.module').then((m) => m.SignInModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'forgot',
    loadChildren: () => import('./pages/forgot/forgot.module').then((m) => m.ForgotModule),
  },
  {
    path: 'confirm',
    loadChildren: () => import('./pages/confirm/confirm.module').then((m) => m.ConfirmModule),
  },
  {
    path: 'main',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/main/main.module').then((m) => m.MainModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'reportLog',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/report-log/report-log.module').then((m) => m.ReportLogModule),
  },
  {
    path: 'reportVendor',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/report-vendor/report-vendor.module').then((m) => m.ReportVendorModule),
  },
  {
    path: 'reportTransaction',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/report-transaction/report-transaction.module').then((m) => m.ReportTransactionModule),
  },
  {
    path: 'reportTransactionUser',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/report-transaction-user/report-transaction-user.module').then((m) => m.ReportTransactionUserModule),
  },
  {
    path: 'reportUpload',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/report-upload/report-upload.module').then((m) => m.ReportUploadModule),
  },
  {
    path: 'question',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/question/question.module').then((m) => m.QuestionModule),
  },
  {
    path: 'mainManage',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/main-manage/main-manage.module').then((m) => m.MainManageModule),
  },
  {
    path: 'questionManage',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/question-manage/question-manage.module').then((m) => m.QuestionManageModule),
  },
  {
    path: 'changeEmail',
    loadChildren: () => import('./pages/change-email/change-email.module').then((m) => m.ChangeEmailModule),
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
