import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  note: string;
}

@Component({
  selector: 'app-dialog-note-view',
  templateUrl: './dialog-note-view.component.html',
  styleUrls: ['./dialog-note-view.component.scss'],
})
export class DialogNoteViewComponent implements OnInit {
  listResult = [];
  listResultError = [];
  tableSucces = true;

  isDisableRefresh = true;

  allPage: any;

  constructor(
    public dialogRef: MatDialogRef<DialogNoteViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  onNoClick(): void {
    this.dialogRef.close({ event: true, close: true });
  }
}
