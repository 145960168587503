import { Component, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoadingScreenService } from './core/services/loading-screen/loading-screen.service';
import { SidebarService } from './core/services/sidebar.service';
import { Utils } from './shared/utils/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'web-vendor-client';
  isCheckSignIn = false;
  constructor(
    public sidebarService: SidebarService,
    public utils: Utils,
    private router: Router,
    public loadingScreenService: LoadingScreenService
  ) {
    // this.router.events.subscribe((val) => {
    //   if (val instanceof NavigationEnd) {
    //     const element = document.querySelector(".main")
    //     if (!!element) {
    //       element.scrollIntoView({ behavior: 'smooth' })
    //     }
    //   }
    // });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    const page = window.location.href.split('/');
    this.isCheckSignIn = this.utils.checkPath(page[page.length - 1]);
    if(this.isCheckSignIn && page[page.length - 1] !== 'main'){
      window.location.replace('./main');
    }
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit');
  }
}
