import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserMatrix } from '../models/user-matrix';
import { UserProfile } from '../models/user-profile';
import { EncryptDecryptService } from './encrypt-decrypt.service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private userProfile!: UserProfile;
  private userMatrix!: UserMatrix;
  private jwt = '';

  constructor(private router: Router, private encryptDecryptService: EncryptDecryptService) {}

}
