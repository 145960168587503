import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { catchError, map, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MainPageService {
  constructor(private httpClient: HttpClient) {}

  update(id: any, payload: any): Promise<any> {
    return this.httpClient
      .post(`${environment.apiUrl}` + '/mainPage/update/' + id , payload)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return of(err);
        }),
        take(1),
      )
      .toPromise();
  }

  search(): Promise<any> {
    return this.httpClient
      .post(`${environment.apiUrl}` + '/mainPage/search', '')
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return of(err);
        }),
        take(1),
      )
      .toPromise();
  }

  create(payload: any): Promise<any> {
    return this.httpClient
      .post(`${environment.apiUrl}` + '/mainPage/create', payload)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return of(err);
        }),
        take(1),
      )
      .toPromise();
  }

}
