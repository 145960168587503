import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { catchError, map, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private httpClient: HttpClient) {}

  update(id: any, payload: any): Promise<any> {
    return this.httpClient
      .post(`${environment.apiUrl}` + '/user/update/' + id , payload)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return of(err);
        }),
        take(1),
      )
      .toPromise();
  }

  search(payload: any): Promise<any> {
    return this.httpClient
      .post(`${environment.apiUrl}` + '/user/search', payload)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return of(err);
        }),
        take(1),
      )
      .toPromise();
  }

  exportExcel(payload: any): Promise<any> {
    return this.httpClient
      .post(`${environment.apiUrl}` + '/user/exportExcel', payload
      ,{ responseType: 'arraybuffer' })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return of(err);
        }),
        take(1),
      )
      .toPromise();
  }

  delete(id: any): Promise<any> {
    return this.httpClient
      .delete(`${environment.apiUrl}` + '/user/delete/' + id)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return of(err);
        }),
        take(1),
      )
      .toPromise();
  }

  forgot(payload: any): Promise<any> {
    return this.httpClient
      .post(`${environment.apiUrl}` + '/user/forgot', payload)
      .pipe(
        map((data) => {
          console.log('data : ', data);
          return data;
        }),
        catchError((err) => {
          return of(err);
        }),
        take(1),
      )
      .toPromise();
  }

  reset(token: any, payload: any): Promise<any> {
    return this.httpClient
      .post(`${environment.apiUrl}` + '/user/reset/' + token , payload)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return of(err);
        }),
        take(1),
      )
      .toPromise();
  }

  resetPassword(id: any): Promise<any> {
    return this.httpClient
      .get(`${environment.apiUrl}` + '/user/reset/' + id)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return of(err);
        }),
        take(1),
      )
      .toPromise();
  }

}
