import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/core/services/user/user.service';
import { interval } from 'rxjs';
export interface DialogData {
  rowData: any;
}

@Component({
  selector: 'app-dialog-confirm-remove',
  templateUrl: './dialog-confirm-remove.component.html',
  styleUrls: ['./dialog-confirm-remove.component.scss'],
})
export class DialogConfirmRemoveComponent implements OnInit {
  listResult = [];
  listResultError = [];
  tableSucces = true;

  isDisableRefresh = true;

  allPage: any;

  name: string = '';
  taxId: string = '';
  id: any;

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmRemoveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _snackBar: MatSnackBar,
    private userService: UserService,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.name = this.data.rowData.name || '';
    this.taxId = this.data.rowData.username || '';
    this.id = this.data.rowData.id || null;
  }

  onNoClick(): void {
    this.dialogRef.close({ event: true, close: true });
  }

  onConfirm(){
    if(this.id){
      this.userService.delete(this.id).then((response) => {
        console.log('response',response)
        if (!response.error) {
          this._snackBar.open('ลบรายการสำเร็จ', '', {
            panelClass: '_success',
          });
          interval(2000).subscribe(() => {
            window.location.replace('/reportVendor');
          });
        }
      });
    }
  }
}
