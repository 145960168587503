import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from "../auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }
  canActivate() {
    console.log(this.authService.isLoggedIn());
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['/login']);
      return false;
    } else {
      if (!this.authService.getSessionStorage('userProfile').email) {
        this.router.navigate(['/changeEmail']);
        return true;
      }
      console.log(this.authService.getSessionStorage('userProfile').changeFlag)
      if (this.authService.getSessionStorage('userProfile').changeFlag) {
        this.router.navigate(['/profile']);
        return true;
      }
      return true
    }
  }

}
