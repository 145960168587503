import { Injectable } from '@angular/core';
import {Observable, of, retry, throwError} from 'rxjs';

import { catchError, map, take } from 'rxjs/operators';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {Router} from "@angular/router";
import {EncryptDecryptService} from "../encrypt-decrypt.service";
import {UserProfile} from "../../models/user-profile";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userProfile!: UserProfile;

  constructor(private router: Router, private httpClient: HttpClient, private encryptDecryptService: EncryptDecryptService) {}

  handleError(error: HttpErrorResponse) {
    console.log(error)
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      return of(error);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

  register(payload: any): Promise<any> {
    return this.httpClient
      .post(`${environment.apiUrl}` + '/public/register', payload)
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return of(err);
        }),
        take(1),
      )
      .toPromise();
  }

  login(payload: any): Observable<any> {
    // return this.httpClient
    //   .post(`${environment.apiUrl}` + '/public/login', payload)
    //   .pipe(
    //     map((data) => {
    //       return data;
    //     }),
    //     catchError((err) => {
    //       return of(err);
    //     }),
    //     take(1),
    //   )
    //   .toPromise();

    return this.httpClient.post<HttpResponse<UserProfile>>(`${environment.apiUrl}` + '/public/login', payload, { observe: 'response' })
      .pipe(retry(2), catchError(this.handleError));
  }

  setUser(resp: any) {
    this.setSessionStorage('userProfile', resp.body);
    this.setSessionStorage('token', resp.headers.get('Authorization'));
    this.setSessionStorage('permission', resp.body.role);
    this.setSessionStorage('username', resp.body.username);
    // this.router.navigate(['/main']);
    window.location.replace('./main');
  }

  isLoggedIn() {
    return sessionStorage.getItem('token') != null;
  }

  logout() {
    sessionStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  setSessionStorage(key: string, value: any) {
    const encryptedValue = this.encryptDecryptService.encrypt(JSON.stringify(value));
    sessionStorage.setItem(key, JSON.stringify(encryptedValue));
    return value;
  }

  getSessionStorage(key: string) {
    if (sessionStorage.getItem(key)) {
      const encrypted = JSON.parse(sessionStorage.getItem(key)!);
      const decrypt = this.encryptDecryptService.decrypt(encrypted);
      return JSON.parse(decrypt);
    }
    return null;
  }

  clearSessionStorage(key: string) {
    sessionStorage.removeItem(key);
  }

  getUserProfile() {
    console.log('getUserProfile')
    if (this.userProfile) {
      return this.userProfile;
    } else {
      if (!sessionStorage.getItem('userProfile')) {
      } else {
        const encrypted = JSON.parse(sessionStorage.getItem('userProfile')!);
        const decryp = this.encryptDecryptService.decrypt(encrypted);
        this.userProfile = JSON.parse(decryp);
      }

      return this.userProfile;
    }
  }
}
