import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserProfile } from 'src/app/core/models/user-profile';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { SidebarService } from 'src/app/core/services/sidebar.service';
import { Constant } from '../../constants';
import {AuthService} from "../../../core/services/auth/auth.service";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  isPage = '';
  permission = '';
  userProfile!: UserProfile;
  headMenu = 'เลือกรายการที่ต้องการ';
  isSidebarVisible = true;
  listMenu!: any;
  events: string[] = [];
  opened: boolean = true;
  menuClassMobile = '';
  constructor(
    private router: Router,
    private constant: Constant,
    private _snackBar: MatSnackBar,
    private authService: AuthService,
    public  sidebarService: SidebarService
  ) {
    this.setItems();

    this.sidebarService.sidebarVisibilityChange.subscribe((value) => {
      this.isSidebarVisible = this.sidebarService.isSidebarVisible;
    });

    this.sidebarService.sidebarToggleChange.subscribe((value) => {
      this.menuClassMobile = value ? 'opened' : '';
    });

  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.userProfile = this.authService.getUserProfile();
    const page = window.location.href.split('/');
    this.isPage = page[page.length - 1];
    this.permission = this.authService.getSessionStorage('permission');

    console.log('userProfile',this.userProfile)
    this.onCheckPermission(this.permission);
  }

  setItems(){
    this.listMenu = this.constant.LIST_MENU;
  }

  onCheckPermission(_value: string | null) {
    if (!_value) {
      this._snackBar.open('กรุณาเข้าสู่ระบบ', '', {
        panelClass: '_warning',
      });
      window.location.replace('./');
    }
  }

  onActive(page: string) {
    this.router.navigate([page]);
    console.log(page)
    this.listMenu.forEach((element: { active: boolean; }) => {
      element.active = false;
    });
    this.listMenu.find((item: { path: string; role: string; }) => item.path === page && item.role === this.permission).active = true;
  }

  logout() {
    this.authService.clearSessionStorage('permission');
    this._snackBar.open('ออกจากระบบสำเร็จ', '', {
      panelClass: '_success',
    });
    window.location.replace('./');
  }

  mainPage() {
    window.location.replace('./main');
  }

  toggleSideBar(flag: boolean) {
    this.sidebarService.toggleSidebarVisibility(flag);
  }

}
