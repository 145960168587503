import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { interval } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FAQService } from 'src/app/core/services/faq/faq.service';
export interface DialogData {
  item: any;
}

@Component({
  selector: 'app-dialog-faq-edit',
  templateUrl: './dialog-faq-edit.component.html',
  styleUrls: ['./dialog-faq-edit.component.scss'],
})
export class DialogFAQEditComponent implements OnInit {
  faqFormGroup!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogFAQEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _snackBar: MatSnackBar,
    private faqService: FAQService,
    private formBuilder: FormBuilder
  ) {
    dialogRef.disableClose = true;
    this.createFormGroup();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  createFormGroup() {
    this.faqFormGroup = this.formBuilder.group({
      question: this.formBuilder.control(this.data.item.question),
      answer: this.formBuilder.control(this.data.item.answer),
    });
  }

  onNoClick(): void {
    this.dialogRef.close({ event: true, close: true });
  }

  onConfirm() {
    // call service
    const form = this.faqFormGroup.getRawValue();
    const payload = {
      question: form.question,
      answer: form.answer,
    };
    if (form.question && form.answer) {
      this.faqService.update(this.data.item.id, payload).then((response) => {
        if (response && response.id) {
          this._snackBar.open('แก้ไขคำถาม - คำตอบสำเร็จ', '', {
            panelClass: '_success',
          });
          interval(500).subscribe(() => {
            window.location.replace('/questionManage');
          });
        } else {
          this._snackBar.open('เกิดข้อผิดพลาด กรุณาทำรายการใหม่', '', {
            panelClass: '_warning',
          });
        }
      });
    } else {
      this._snackBar.open('กรุณาระบุข้อมูลให้ครบถ้วน', '', {
        panelClass: '_warning',
      });
    }
  }
}
