import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { interval } from 'rxjs';
import { FAQService } from 'src/app/core/services/faq/faq.service';
export interface DialogData {
  _id: any;
}

@Component({
  selector: 'app-dialog-faq-delete',
  templateUrl: './dialog-faq-delete.component.html',
  styleUrls: ['./dialog-faq-delete.component.scss'],
})
export class DialogFAQDeleteComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogFAQDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _snackBar: MatSnackBar,
    private faqService: FAQService,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  onNoClick(): void {
    this.dialogRef.close({ event: true, close: true });
  }

  onConfirm() {
    this.faqService.delete(this.data._id).then((response) => {
      if (response && response.id) {
        this._snackBar.open('ลบข้อมูลสำเร็จ', '', {
          panelClass: '_success',
        });
        interval(500).subscribe(() => {
          window.location.replace('/questionManage');
        });
      } else {
        this._snackBar.open('เกิดข้อผิดพลาด กรุณาทำรายการใหม่', '', {
          panelClass: '_warning',
        });
      }
    });
  }
}
