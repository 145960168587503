import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingScreenService {
  isLoadingVisable = false;
  loadingVisableChange: Subject<boolean> = new Subject<boolean>();

  constructor() {
    this.loadingVisableChange.subscribe((value) => {
      this.isLoadingVisable = value;
    });
  }

  loadingToggleStatus(flag: boolean) {
    this.loadingVisableChange.next(flag);
  }
}
