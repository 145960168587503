import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  currentGroup: any;
  sidebarUserProfileChange: any;
  currentModule(currentModule: any) {
    throw new Error('Method not implemented.');
  }
  isSidebarVisible = true;

  nowPage: string | undefined;

  pageType: string | undefined;

  isDisplayMobile: boolean | undefined;

  sidebarVisibilityChange: Subject<boolean> = new Subject<boolean>();

  sidebarNowPageChange: Subject<string> = new Subject<string>();

  sidebarPageTypeChange: Subject<string> = new Subject<string>();

  sidebarToggleChange: Subject<boolean> = new Subject<boolean>();

  constructor() {
    this.sidebarVisibilityChange.subscribe((value) => {
      this.isSidebarVisible = value;
    });

    this.sidebarNowPageChange.subscribe((value) => {
      this.nowPage = value;
    });

    this.sidebarPageTypeChange.subscribe((value) => {
      this.pageType = value;
    });

    this.sidebarToggleChange.subscribe((value) => {
      this.isDisplayMobile = value;
    });
  }

  toggleSidebarVisibility(flag: boolean) {
    this.sidebarVisibilityChange.next(flag);
  }

  updateNowPage(nowPage: string) {
    this.sidebarNowPageChange.next(nowPage);
  }

  updatePageType(pageType: string) {
    this.sidebarPageTypeChange.next(pageType);
  }

  toggleSidebar(isDisplayMobile: boolean) {
    this.sidebarToggleChange.next(isDisplayMobile);
  }
}
