import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../utils/utils';

@Pipe({
  name: 'thaiLang',
})
export class ThaiLangPipe implements PipeTransform {
  constructor(public utils: Utils) {}
  transform(key: string): string {
    return this.utils.mappingThaiLanguage(key);
  }
}
